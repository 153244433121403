
import { Component, Prop, Vue } from "vue-property-decorator";
import { Product } from "@fundraisingbureauet/admin-typescript-client";

@Component({
  components: {}
})
export default class UsageRecords extends Vue {
  @Prop({ required: true }) product!: Product;
  showBody = false;

  get ShowBody() {
    return this.showBody;
  }

  set ShowBody(value) {
    this.showBody = value;
  }

  private ShowBodyAndLoadData() {
    this.ShowBody = !this.ShowBody;
  }
}
